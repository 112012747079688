<template>
    <div class="bg-white shadow-xl-white px-4 py-5 sm:rounded-lg sm:p-6">
        <div class="space-y-6 sm:space-y-5">
            <div>
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Personal Information
                </h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    View and change your personal details.
                </p>
            </div>
            <div class="space-y-6 sm:space-y-5">
                <div>
                    <div class="my-6 sm:my-5">
                        <div v-if="!isEditingName" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                            <div class="mt-1 sm:mt-0 sm:col-span-1 ">
                                <span class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Full Name
                                </span>
                            </div>
                            <div class="mt-1 sm:mt-0 sm:col-span-1 ">
                                <p class="max-w-lg block w-full sm:max-w-xs text-base text-gray-500 sm:pt-2 font-medium">
                                    {{ fullName }}
                                </p>
                            </div>
                            <div class="mt-1 sm:mt-0 sm:col-span-1 flex justify-end">
                                <button v-if="isEditingName" @click="finishEditingName" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-300 p-2 hover:bg-gray-200  border-0">
                                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                </button>

                                <button v-else @click="startEditingName" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-300 p-2 hover:bg-gray-200  border-0">
                                    <svg class="w-5 h-5 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="isEditingName" class="space-y-6 sm:space-y-5">
                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label for="first_name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                First name
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <input type="text" name="first_name" id="first_name" autocomplete="given-name" v-model="newName.firstName" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                            </div>
                        </div>

                        <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                            <label for="last_name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Last name
                            </label>

                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                                <input type="text" name="last_name" id="last_name" autocomplete="family-name" v-model="newName.lastName" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                            </div>
                        </div>
                        <div class="mt-8 border-gray-200 pt-5">
                            <div class="flex justify-end">
                                <span class="inline-flex rounded-md shadow-sm">
                                    <button type="button" @click="finishEditingName" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                      Cancel
                                    </button>
                                </span>
                                <span class="ml-3 inline-flex rounded-md shadow-sm">
                                    <button @click="updateName" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                      Update Name
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!isEditingUsername" class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 ">
                    <div class="mt-1 sm:mt-0 sm:col-span-1 ">
                        <span class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Username
                        </span>
                    </div>
                    <div class="mt-1 sm:mt-0 sm:col-span-1 ">
                        <p class="max-w-lg block w-full sm:max-w-xs text-base text-gray-500 sm:pt-2 font-medium">
                            @{{ username }}
                        </p>
                    </div>
                    <div class="mt-1 sm:mt-0 sm:col-span-1 flex justify-end">
                        <button v-if="isEditingUsername" @click="finishEditingUsername" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-300 p-2 hover:bg-gray-200  border-0">
                            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                        </button>

                        <button v-else @click="startEditingUsername" class="text-jiruto-zotMain hover:text-jiruto-zotDarker rounded-full bg-gray-300 p-2 hover:bg-gray-200  border-0">
                            <svg class="w-5 h-5 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/></svg>
                        </button>
                    </div>
                </div>

                <div v-if="isEditingUsername" class="space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="username" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Username
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <input type="text" v-model="newUsername" name="username" id="username" autocomplete="given-name" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                        </div>
                    </div>
                    <div class="mt-8  border-gray-200 pt-5">
                        <div class="flex justify-end">
                            <span class="inline-flex rounded-md shadow-sm">
                                <button type="button" @click="finishEditingUsername" class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
                                    Cancel
                                </button>
                            </span>
                            <span class="ml-3 inline-flex rounded-md shadow-sm">
                                <button @click="updateUsername" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                    Update username
                                </button>
                            </span>
                        </div>
                    </div>
                </div>

<!--                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">-->
<!--                    <label for="email" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">-->
<!--                        Email address-->
<!--                    </label>-->
<!--                    <div class="mt-1 sm:mt-0 sm:col-span-1">-->
<!--                        <input id="email" name="email" type="email" autocomplete="email" class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">-->
<!--                    <label for="country" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">-->
<!--                        Country / Region-->
<!--                    </label>-->
<!--                    <div class="mt-1 sm:mt-0 sm:col-span-2">-->
<!--                        <select id="country" name="country" autocomplete="country" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">-->
<!--                            <option>United States</option>-->
<!--                            <option>Canada</option>-->
<!--                            <option>Mexico</option>-->
<!--                        </select>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">-->
<!--                    <label for="street_address" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">-->
<!--                        Street address-->
<!--                    </label>-->
<!--                    <div class="mt-1 sm:mt-0 sm:col-span-2">-->
<!--                        <input type="text" name="street_address" id="street_address" autocomplete="street-address" class="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md">-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">-->
<!--                    <label for="city" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">-->
<!--                        City-->
<!--                    </label>-->
<!--                    <div class="mt-1 sm:mt-0 sm:col-span-2">-->
<!--                        <input type="text" name="city" id="city" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md">-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">-->
<!--                    <label for="state" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">-->
<!--                        State / Province-->
<!--                    </label>-->
<!--                    <div class="mt-1 sm:mt-0 sm:col-span-2">-->
<!--                        <input type="text" name="state" id="state" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md">-->
<!--                    </div>-->
<!--                </div>-->

<!--                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">-->
<!--                    <label for="zip" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">-->
<!--                        ZIP / Postal-->
<!--                    </label>-->
<!--                    <div class="mt-1 sm:mt-0 sm:col-span-2">-->
<!--                        <input type="text" name="zip" id="zip" autocomplete="postal-code" class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md">-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>
    </div>

</template>

<script>
    import * as axios from '@/axios-auth'
    export default {
        name: "SettingsGeneral",
        data(){
            return {
                isEditingName: false,
                isEditingUsername: false,
                newName : {
                    firstName : "",
                    lastName : "",
                },
                newUsername: "",
            }
        },
        computed: {
            firstName(){
                return this.$store.getters.getFirstName
            },
            lastName(){
                return this.$store.getters.getLastName
            },
            username(){
                return this.$store.getters.getUsername
            },
            fullName(){
                return this.firstName + " " + this.lastName
            }
        },
        methods: {
            startEditingName(){
                this.newName.firstName = this.firstName
                this.newName.lastName = this.lastName
                this.isEditingName = true
            },
            finishEditingName(){
                this.isEditingName = false
            },
            startEditingUsername(){
                this.newUsername = this.username
                this.isEditingUsername = true
            },
            finishEditingUsername(){
                this.isEditingUsername = false
            },
            updateName(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                axios.jigo.put('/v2/user/profile/name', this.newName).then( (resp) => {
                    if (resp.status !== 200) {
                        console.log("Unwanted status: ", resp.status, resp)
                    }
                    this.isEditingName = false
                    this.$store.dispatch("updateName", this.newName)
                    loader.hide()
                }).catch((err) => {
                    loader.hide()
                    console.error("ERROR: updateName FAILED", err)
                })
            },
            updateUsername(){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                axios.jigo.put('/v2/user/profile/username', this.newUsername).then( (resp) => {
                    if (resp.status !== 200) {
                        console.log("Unwanted status: ", resp.status, resp)
                    }
                    this.isEditingUsername = false
                    this.$store.dispatch("updateUsername", this.newUsername)
                    loader.hide()
                }).catch((err) => {
                    loader.hide()
                    console.error("ERROR: updateUsername FAILED", err)
                })
            }
        },
    }
</script>

<style scoped>

</style>
